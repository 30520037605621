import React from 'react';

import LayoutOrig from "../../components/layout_fr"
import Seo from "../../components/seo"
import {Container} from "react-bootstrap"
import {graphql} from 'gatsby'
import Markdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import tree from "../../images/tree.svg"

const API_URL = process.env.API_URL;

const Mentions = ({data})=> {


return (
  <LayoutOrig>
   
   <Seo  
      titleTemplate={`Mentions légales | Trezorino`}
      title="Mentions légales"
      description="Mentions légales"
      image={tree}
      lang="fr"
      />
    <div className="header-box-tarifs">
      

      <Container className="pt-5" style={{paddingBottom:"20%"}} >

      <div className="d-flex align-items-start">
    
          <div className="tab-content col-8" style={{Height:"100%"}}>

          {data.allStrapiLegales.edges.map(document => (
            <div  key={document.node.Identity}>
              <Markdown children={document.node.Content} skipHtml={false} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} transformImageUri={uri => uri.startsWith('http') ? uri : `${API_URL}${uri}`}/>
            </div>
            ))}

          </div>
        </div>
    
    
    </Container>



    </div>
   
  </LayoutOrig>
)
}

export default Mentions

export const data = graphql`  
query mention {
  allStrapiLegales(filter: {Id_content: {eq: "mentions"}}) {
    edges {
      node {
        Content
        Identity
        Titre
        Data_bs_target
        Id_content
        Order
        Class_content
        Class
        Arial_controls
        Aria_selected
      }
    }
  }
}
`
